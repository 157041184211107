import "./style.scss";

import { useState } from "react";
import Stack from "@mui/material/Stack";
import RadioGroup from "@mui/material/RadioGroup";
import { Modal, Button, Radio, DurationInput } from "src/components";
import themeColor from "src/constants/themeColor";
import { addItems, setSelectedFileList } from "src/redux/slices/mainPage";
import { useDispatch, useSelector } from "src/redux/store";

export default function AddPlaylistModal({ open, items, onClose }) {
  const [position, setPosition] = useState("current");
  const [loading, setLoading] = useState(false);
  const [startFrom, setStartFrom] = useState(0);
  // const [duration, setDuration] = useState(() =>
  //   items.length === 1 ? parseInt(items[0].duration) : 0
  // );
  const [durationType, setDurationType] = useState("full");
  const dispatch = useDispatch();
  const { channel } = useSelector((state) => state.mainPage);
  const duration = items.length === 1 ? parseInt(items[0].duration) : 0;

  const handleAddPlaylist = async () => {
    setLoading(true);
    await dispatch(addItems(channel, position, startFrom, duration, items));
    await dispatch(setSelectedFileList([]));
    onClose();
  };

  const changeStartFrom = (startFromValue) => {
    if (duration > startFromValue) {
      setStartFrom(startFromValue);
    }
  };

  return (
    <Modal
      className="add-playlist-modal"
      open={open}
      onClose={onClose}
      color={themeColor["--primary-color"]}
      textColor="white"
      loading={loading}
    >
      <div className="subject text-center">Add</div>
      <div className="content">
        <div className="sub-title">POSITION</div>
        <RadioGroup
          name="position"
          value={position}
          onChange={(e) => setPosition(e.target.value)}
        >
          <Radio color="white" value="current" label="Right Now" />
          <Radio color="white" value="aftercurrent" label="Next" />
          <Radio color="white" value="last" label="Last" />
        </RadioGroup>
        <div className="sub-title">DURATION</div>
        <RadioGroup
          name="repeat"
          value={durationType}
          onChange={(e) => setDurationType(e.target.value)}
        >
          {items.length === 1 && (
            <Stack direction="row" alignItems="center">
              <Radio color="white" value="startFrom" />
              <DurationInput
                hideDate
                color="white"
                value={startFrom}
                onChange={changeStartFrom}
              />
              {"-"}
              <DurationInput hideDate color="white" value={duration} />
            </Stack>
          )}
          <Radio color="white" value="full" label="Full" />
        </RadioGroup>
      </div>
      <div className="actions">
        <Button color="white" onClick={() => handleAddPlaylist()}>
          Add
        </Button>
      </div>
    </Modal>
  );
}
