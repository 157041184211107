import { useState, useEffect, useRef } from "react";
// import { useDispatch } from "src/redux/store";

import startPlay from "src/webrtc/startPlay";
import stopPlay from "src/webrtc/stopPlay";

export default function WebRTCPlayer({
  size,
  signalingURL,
  applicationName,
  streamName,
  play,
}) {
  const [playStart, setPlayStart] = useState(play);
  const [playStarting, setPlayStarting] = useState(false);
  const [playStop, setPlayStop] = useState(false);
  const [playStopping, setPlayStopping] = useState(false);

  const [websocket, setWebsocket] = useState(null);
  const [peerConnection, setPeerConnection] = useState(null);
  const [audioTrack, setAudioTrack] = useState(null);
  const [videoTrack, setVideoTrack] = useState(null);
  const [connected, setConnected] = useState(null);

  const videoElement = useRef(null);

  // Listen for changes in the play* flags in the tore
  // and stop or stop playback accordingly

  useEffect(() => {
    if (playStart && !playStarting && !connected) {
      setPlayStart(false);
      setPlayStarting(true);
      startPlay(
        {
          signalingURL,
          applicationName,
          streamName,
          playStart,
          playStarting,
          playStop,
          playStopping,
        },
        websocket,
        {
          onError: (error) => {
            console.log(error);
          },
          onConnectionStateChange: (result) => {
            setConnected(result.connected);
          },
          onSetPeerConnection: (result) => {
            setPeerConnection(result.peerConnection);
          },
          onSetWebsocket: (result) => {
            setWebsocket(result.websocket);
          },
          onPeerConnectionOnTrack: (event) => {
            if (event.track != null && event.track.kind != null) {
              if (event.track.kind === "audio") {
                setAudioTrack(event.audioTrack);
              } else if (event.track.kind === "video") {
                setVideoTrack(event.track);
              }
            }
          },
        }
      );
    }
    if (playStarting && connected) {
      setPlayStarting(false);
    }

    if (playStop && !playStopping && connected) {
      setPlayStop(false);
      setPlayStopping(true);
      stopPlay(peerConnection, websocket, {
        onSetPeerConnection: (result) => {
          setPeerConnection(result.peerConnection);
        },
        onSetWebsocket: (result) => {
          setWebsocket(result.websocket);
        },
        onPlayStopped: () => {
          setConnected(false);
        },
      });
    }

    if (playStopping && !connected) {
      setPlayStopping(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    videoElement,
    peerConnection,
    websocket,
    connected,
    playStart,
    playStarting,
    playStop,
    playStopping,
  ]);

  useEffect(() => {
    if (connected) {
      let newStream = new MediaStream();
      if (audioTrack != null) newStream.addTrack(audioTrack);

      if (videoTrack != null) newStream.addTrack(videoTrack);

      if (videoElement != null && videoElement.current != null)
        videoElement.current.srcObject = newStream;
    } else {
      if (videoElement != null && videoElement.current != null)
        videoElement.current.srcObject = null;
    }
  }, [audioTrack, videoTrack, connected, videoElement]);

  if (!connected) return null;

  return (
    <video
      style={{ borderRadius: "5px" }}
      id="webrtc-video-player"
      ref={videoElement}
      playsInline
      // muted
      controls
      width={size.width}
      height={size.height}
    />
  );
}
