import "./style.scss";

import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import MuiButton from "src/components/Button/MuiButton";
import MainLoadingScreen from "src/components/Loading/MainLoadingScreen";
import { useDispatch, useSelector } from "src/redux/store";
import {getViewChanels, getViewCurrentItem, serverDoAction} from "src/redux/slices/mainPage";
import useAuth from "src/hooks/useAuth";
import { CustomScrollContainer } from "src/container";
import MainPage from "./MainPage";
import CircularProgress from '@mui/material/CircularProgress';

export default function MainApp() {
  const [loading, setLoading] = useState(true);
  const [openNoChannelAlert, setOpenNoChannelAlert] = useState(false);
  const [dockPanel, setDockPanel] = useState("");
  const [viewCurrentItemInterval, setViewCurrentItemInterval] = useState(0);
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const { mainPage } = useSelector((state) => state);
  const [serverIsRefreshing, setServerIsRefreshing] = useState(false);

  useEffect(() => {
    const getData = async () => {
      await dispatch(getViewChanels());
      setLoading(false);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mainPage.defaultChannelData.length === 0 && !loading) {
      setOpenNoChannelAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, mainPage.defaultChannelData]);

  useEffect(() => {
    let interval;
    console.log(mainPage)
    if (mainPage.channel !== "") {
      dispatch(getViewCurrentItem(mainPage.channel));
      if (viewCurrentItemInterval) {
        clearInterval(viewCurrentItemInterval);
      }
      interval = setInterval(async () => {
        await dispatch(getViewCurrentItem(mainPage.channel));
      }, 60000);
      setViewCurrentItemInterval(interval);
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainPage.channel]);

  const actionAfterServerRefreshing = async () => {
    await dispatch(getViewChanels());
    let interval;
    console.log(mainPage)
    if (mainPage.channel !== "") {
      dispatch(getViewCurrentItem(mainPage.channel));
      if (viewCurrentItemInterval) {
        clearInterval(viewCurrentItemInterval);
      }
      interval = setInterval(async () => {
        await dispatch(getViewCurrentItem(mainPage.channel));
      }, 60000);
      setViewCurrentItemInterval(interval);
    }
  }

  const restartProcess = async () => {
    setServerIsRefreshing(true);
    await dispatch(serverDoAction('restart'));
    console.log("restarted")
    const timeout = setTimeout(async () => {
      await actionAfterServerRefreshing();
      clearTimeout(timeout);
      setServerIsRefreshing(false);
    }, 10000);
  }

  if (mainPage.defaultChannelData.length === 0) {
    if (loading) {
      return <MainLoadingScreen sx={{ minHeight: "100vh" }} />;
    } else {
      return (
        <Dialog maxWidth="sm" fullWidth open={openNoChannelAlert}>
          <DialogTitle
            sx={{ "&.MuiDialogTitle-root": { padding: "5px 10px" } }}
          >
            ACAS
          </DialogTitle>
          <DialogContent dividers>
            You don't have an active channel. Please logout and contact our
            customer support for help at support@acan.email
          </DialogContent>
          <DialogActions>
            <MuiButton
                disabled={serverIsRefreshing ? true : false}
                onClick={async () => {
                  await restartProcess();
                }}
            >
              {serverIsRefreshing ? (<>Please wait &nbsp; <CircularProgress size={12} /></>) : 'Refresh my channel'}
            </MuiButton>
            <MuiButton
              onClick={() => {
                setOpenNoChannelAlert(false);
                logout();
              }}
            >
              Logout
            </MuiButton>
          </DialogActions>
        </Dialog>
      );
    }
  } else {
    return (
      <div className="main-page">
        <CustomScrollContainer>
          <div className="dflex">
            <div
              className={`overlay${dockPanel ? " active" : ""}`}
              onClick={() => setDockPanel("")}
            />
            <MainPage dockPanel={dockPanel} />
            <div className="left-touch" onClick={() => setDockPanel("left")}>
              <i className="fa fa-align-justify"></i>
            </div>
            <div className="right-touch" onClick={() => setDockPanel("right")}>
              <i className="fa fa-align-justify"></i>
            </div>
          </div>
        </CustomScrollContainer>
      </div>
    );
  }
}
