import "./style.scss";

import React, { useState } from "react";

import RadioGroup from "@mui/material/RadioGroup";
import Button from "@mui/material/Button";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import moment from "moment";

import { Modal, Radio, InputField } from "src/components";
import themeColor from "src/constants/themeColor";
import { addLive } from "src/redux/slices/mainPage";
import { useDispatch, useSelector } from "src/redux/store";
import MuiInput from "src/components/InputText/MuiInput";
import { useEffect } from "react";

export default function GoLiveModal({ open, onClose, items = [], playlistId }) {
  const [title, setTitle] = useState("My Live");
  const [position, setPosition] = useState("current");
  const [startTime, setStartTime] = useState(new Date());
  const [stopTime, setStopTime] = useState(moment().add(30, "minutes"));

  const [loading, setLoading] = useState(false);

  const { mainPage } = useSelector((state) => state);
  const { selectedLive, channel } = mainPage;
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedLive !== "" && items.length === 0) {
      setTitle(`My Live ${selectedLive}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLive]);

  useEffect(() => {
    if (startTime.valueOf() >= stopTime.valueOf()) {
      setStopTime(moment(startTime).add(30, "minutes"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime]);

  useEffect(() => {
    if (startTime.valueOf() >= stopTime.valueOf()) {
      setStartTime(moment(startTime).subtract(30, "minutes"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopTime]);

  const addLiveList = async () => {
    setLoading(true);
    await dispatch(
      addLive({
        channel,
        title,
        startTime: startTime.valueOf(),
        stopTime: stopTime.valueOf(),
        items,
        selectedLive,
      })
    );
    setLoading(false);
    onClose();
  };

  return (
    <Modal
      className="go-live-modal"
      open={open}
      onClose={onClose}
      color={themeColor["--primary-color"]}
      textColor="white"
      loading={loading}
    >
      <div className="subject">Go Live</div>
      <div className="content">
        <div className="sub-title">Title</div>
        <InputField
          color="white"
          textColor="white"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <div className="sub-title">Position</div>
        <RadioGroup
          name="position"
          value={position}
          onChange={(e) => setPosition(e.target.value)}
        >
          <Radio color="white" value="current" label="Right Now" />
          <Radio color="white" value="aftercurrent" label="Special Time" />
          {position === "aftercurrent" && (
            <TimePicker
              ampm={false}
              openTo="hours"
              views={["hours", "minutes", "seconds"]}
              inputFormat="HH:mm:ss"
              mask="__:__:__"
              value={startTime}
              onChange={(newValue) => {
                setStartTime(newValue);
              }}
              renderInput={(params) => (
                <MuiInput
                  {...params}
                  sx={{
                    "& .MuiOutlinedInput-root.MuiInputBase-root": {
                      borderRadius: "4px !important",
                      border: "1px solid white !important",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-input.MuiInputBase-input":
                      {
                        color: "white !important",
                        width: "100%",
                        backgroundColor: "#8EC044 !important",
                      },
                    width: "100%",
                  }}
                />
              )}
            />
          )}
        </RadioGroup>

        <div className="sub-title">Stop Time</div>
        <div className="dflex">
          <DateTimePicker
            ampm={false}
            value={stopTime}
            inputFormat="MM/dd/yyyy HH:mm:ss"
            mask="__/__/____ __:__:__"
            onChange={(newValue) => setStopTime(newValue)}
            renderInput={(params) => (
              <MuiInput
                {...params}
                sx={{
                  "& .MuiOutlinedInput-root.MuiInputBase-root": {
                    borderRadius: "4px !important",
                    border: "1px solid white !important",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-input.MuiInputBase-input":
                    {
                      color: "white !important",
                      width: "100%",
                      backgroundColor: "#8EC044 !important",
                    },
                  width: "100%",
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="actions" style={{ marginTop: 20 }}>
        <Button variant="contained" color="error" onClick={addLiveList}>
          Go Live
        </Button>
      </div>
    </Modal>
  );
}
