// import "./style.scss";

import { useEffect, useMemo } from "react";
import Clappr from "clappr";
// import Box from "@mui/material/Box";

import useAuth from "src/hooks/useAuth";
import { useSelector } from "src/redux/store";
// import WebRTCPlayer from "../WebRTC";

export default function VideoPreview({ id, size }) {
  const { user } = useAuth();
  const { previewItem } = useSelector((state) => state.mainPage);

  const source = useMemo(() => {
    return previewItem === ""
      ? `${user.vodpreview}MusicSN/item-6.mp4/playlist.m3u8`
      : previewItem;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewItem]);

  useEffect(() => {
    let width = "100%",
      height = 168;
    if (size) {
      width = size.width || width;
      height = size.height || height;
    }
    let clappr_player = new Clappr.Player({
      parentId: `#${id}`,
      source,
      poster: "liveprev.png",
      mediacontrol: { seekbar: "#ACD075", buttons: "#8EC044" },
      width: width || "100%",
      height: height || 360,
      borderRadius: "5px",
      hlsjsConfig: {
        enableWorker: true,
      },
    });
    clappr_player.getPlugin("markers-plugin");

    return () => {
      clappr_player.destroy();
      clappr_player = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  const makeStyle = () => {
    return {
      "--panda-video-preview-size": size || "100%",
    };
  };

  return (
    <div className="panda-video-preview-component" style={makeStyle()}>
      <div className="player" id={id} />
    </div>
    // <Box
    //   sx={{
    //     height: 168,
    //     borderRadius: "5px",
    //     backgroundColor: "rgb(142 192 68)",
    //   }}
    // >
    //   <WebRTCPlayer
    //     size={{ height: "100%", width: "100%" }}
    //     signalingURL={user.SignalingURL}
    //     applicationName={user.appName}
    //     streamName={`${channel}${user.overlay ? "_output" : ""}`}
    //     // streamName={source}
    //     play={true}
    //   />
    // </Box>
  );
}
